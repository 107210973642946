import React from 'react'
import classNames from 'classnames'
import Layout from 'layout/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Hidden from '@material-ui/core/Hidden'
import Button from 'components/CustomButtons/Button.js'
import Parallax from 'components/Parallax/Parallax.js'
import landingPageStyle from 'assets/jss/styles/pages/landingPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import LandingServices from 'pages-sections/landing/LandingServices'
import SectionContact from 'pages-sections/SectionContact'
import SectionWorks from 'pages-sections/SectionWorks'
import SectionServicesLanding from 'pages-sections/landing/SectionServicesLanding'

const useStyles = makeStyles(landingPageStyle);

const IndexPage = ({ data }) => {
  const classes = useStyles()

  return (
    <Layout
      pageTitle="Reformas en Barcelona. Construcción de viviendas."
      pageDescription={data.site.siteMetadata.siteDescription}
      headerImageGatsby={data.reformasJson.headerImage.childImageSharp.fluid}
      filter="dark"
      content={
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <h2 className={classes.typist}>Construcción de Viviendas y Reformas</h2>

            <h4 className={classes.subtitle}>A un precio sin compentencia</h4>

            <Link to="/presupuesto">
              <Button color={'primary'} className={classes.navButton}>
                Solicita Presupuesto
              </Button>
            </Link>

            <br />
          </GridItem>
          
          <GridItem xs={12} sm={6}>
            <Hidden smDown>
              <iframe className={classes.video} width="560" height="315" src="https://www.youtube.com/embed/bu4Tf5iQRvE?start=2" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Hidden>
          </GridItem>
          
        </GridContainer>
      }
    >
      <div style={{margin: "auto", textAlign: "center"}}>
        <Hidden mdUp>
          <iframe width="280" height="157" src="https://www.youtube.com/embed/bu4Tf5iQRvE?start=2" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </Hidden>
      </div>
      <SectionServicesLanding />
      <LandingServices classes={classes} />
      <SectionWorks />

      <SectionContact />
    </Layout>
  )  
}

export default IndexPage

export const query = graphql`
  query IndexpageQuery {
    site {
      id
      siteMetadata {
        siteTitle
        siteDescription
      }
    }
    reformasJson {
      title
      subtitle
      headerImage {
        childImageSharp {
          fluid(maxHeight: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;