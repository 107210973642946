import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Gesture from "@material-ui/icons/Gesture";
import Build from "@material-ui/icons/Build";
import BorderColorIcon from '@material-ui/icons/BorderColor';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import servicesStyle from "assets/jss/styles/pages/landing/servicesStyle.js";

const useStyles = makeStyles(servicesStyle);

export default function SectionServicesLanding() {
  const classes = useStyles();
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h1 className={classes.title}>
            Construcción de Viviendas y Reformas en Barcelona
          </h1>
          <h2 className={classes.description}>
            El éxito de <b>Inmovalia Group</b> radica en la calidad y experiencia de sus profesionales. Un adecuado diseño es esencial para llevar con éxito su obra o reforma. 
            Somos especialistas en <b>reformas integrales de pisos</b>, <b>reformas de baños</b> y <b>reformas de cocinas</b>, así como en <b>instalaciones eléctricas</b> y <b>fontanería</b>.
          </h2>
          <h2 className={classes.description}>
            Con un equipo propio de albañiles, electricistas y fontaneros, acompañados del departamento de arquitectura e ingeniería, podemos abarcar cualquier tamaño y complejidad de reformas, poniendo el foco en los acabados y la satisfacción del cliente.
          </h2>
          <h2 className={classes.description}>
            Realizamos <b>construcción de viviendas llave en mano</b>, tanto construcción tradicional como <b>construcción modular prefabricada</b>, siendo nuestra especialidad la <b>construcción con contenedores marítimos</b> reciclados, de altas prestaciones, resistencia y aislamiento a precios más económicos que la construcción tradicional, y con la ventaja de ser más ecológicos y reducir la emisión de CO2.
          </h2>
        </GridItem>
      </GridContainer>
    </div>
  );
}
